import * as _os2 from "os";
var _os = "default" in _os2 ? _os2.default : _os2;
import * as _path2 from "path";
var _path = "default" in _path2 ? _path2.default : _path2;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertPosixPathToPattern = exports.convertWindowsPathToPattern = exports.convertPathToPattern = exports.escapePosixPath = exports.escapeWindowsPath = exports.escape = exports.removeLeadingDotSegment = exports.makeAbsolute = exports.unixify = void 0;
const os = _os;
const path = _path;
const IS_WINDOWS_PLATFORM = os.platform() === "win32";
const LEADING_DOT_SEGMENT_CHARACTERS_COUNT = 2; // ./ or .\\
/**
 * All non-escaped special characters.
 * Posix: ()*?[\]{|}, !+@ before (, ! at the beginning, \\ before non-special characters.
 * Windows: (){}, !+@ before (, ! at the beginning.
 */
const POSIX_UNESCAPED_GLOB_SYMBOLS_RE = /(\\?)([()*?[\]{|}]|^!|[!+@](?=\()|\\(?![!()*+?@[\]{|}]))/g;
const WINDOWS_UNESCAPED_GLOB_SYMBOLS_RE = /(\\?)([(){}]|^!|[!+@](?=\())/g;
/**
 * The device path (\\.\ or \\?\).
 * https://learn.microsoft.com/en-us/dotnet/standard/io/file-path-formats#dos-device-paths
 */
const DOS_DEVICE_PATH_RE = /^\\\\([.?])/;
/**
 * All backslashes except those escaping special characters.
 * Windows: !()+@{}
 * https://learn.microsoft.com/en-us/windows/win32/fileio/naming-a-file#naming-conventions
 */
const WINDOWS_BACKSLASHES_RE = /\\(?![!()+@{}])/g;
/**
 * Designed to work only with simple paths: `dir\\file`.
 */
function unixify(filepath) {
  return filepath.replace(/\\/g, "/");
}
exports.unixify = unixify;
function makeAbsolute(cwd, filepath) {
  return path.resolve(cwd, filepath);
}
exports.makeAbsolute = makeAbsolute;
function removeLeadingDotSegment(entry) {
  // We do not use `startsWith` because this is 10x slower than current implementation for some cases.
  // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
  if (entry.charAt(0) === ".") {
    const secondCharactery = entry.charAt(1);
    if (secondCharactery === "/" || secondCharactery === "\\") {
      return entry.slice(LEADING_DOT_SEGMENT_CHARACTERS_COUNT);
    }
  }
  return entry;
}
exports.removeLeadingDotSegment = removeLeadingDotSegment;
exports.escape = IS_WINDOWS_PLATFORM ? escapeWindowsPath : escapePosixPath;
function escapeWindowsPath(pattern) {
  return pattern.replace(WINDOWS_UNESCAPED_GLOB_SYMBOLS_RE, "\\$2");
}
exports.escapeWindowsPath = escapeWindowsPath;
function escapePosixPath(pattern) {
  return pattern.replace(POSIX_UNESCAPED_GLOB_SYMBOLS_RE, "\\$2");
}
exports.escapePosixPath = escapePosixPath;
exports.convertPathToPattern = IS_WINDOWS_PLATFORM ? convertWindowsPathToPattern : convertPosixPathToPattern;
function convertWindowsPathToPattern(filepath) {
  return escapeWindowsPath(filepath).replace(DOS_DEVICE_PATH_RE, "//$1").replace(WINDOWS_BACKSLASHES_RE, "/");
}
exports.convertWindowsPathToPattern = convertWindowsPathToPattern;
function convertPosixPathToPattern(filepath) {
  return escapePosixPath(filepath);
}
exports.convertPosixPathToPattern = convertPosixPathToPattern;
export default exports;