import * as _fs2 from "@nodelib/fs.walk";
var _fs = "default" in _fs2 ? _fs2.default : _fs2;
import _reader from "./reader";
import _stream from "./stream";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const fsWalk = _fs;
const reader_1 = _reader;
const stream_1 = _stream;
class ReaderAsync extends reader_1.default {
  constructor() {
    super(...arguments);
    this._walkAsync = fsWalk.walk;
    this._readerStream = new stream_1.default(this._settings);
  }
  dynamic(root, options) {
    return new Promise((resolve, reject) => {
      this._walkAsync(root, options, (error, entries) => {
        if (error === null) {
          resolve(entries);
        } else {
          reject(error);
        }
      });
    });
  }
  async static(patterns, options) {
    const entries = [];
    const stream = this._readerStream.static(patterns, options);
    // After #235, replace it with an asynchronous iterator.
    return new Promise((resolve, reject) => {
      stream.once("error", reject);
      stream.on("data", entry => entries.push(entry));
      stream.once("end", () => resolve(entries));
    });
  }
}
exports.default = ReaderAsync;
export default exports;